import React, { createContext, useState, useContext, ReactNode } from 'react';
import { APIEndpoint, APIClient } from '../lib/api_client';
import { useNavigate } from 'react-router-dom';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useAuth } from './auth_provider';

export interface PlanActivity {
  id: string;
  name: string;
  lat: number;
  lng: number;
  place_id?: string;
  vicinity?: string;
}

export interface Plan {
  id?: string;
  name: string;
  activities: PlanActivity[];
  created_at?: string;
  updated_at?: string;
  is_public?: boolean;
}

interface PlanContextType {
  activities: PlanActivity[];
  setActivities: React.Dispatch<React.SetStateAction<PlanActivity[]>>;
  savePlan: (name: string) => Promise<Plan>;
  isAtMaxCapacity: boolean;
  loadPlanForEdit: (plan: Plan) => void;
  deletePlan: (planId: string) => Promise<void>;
  fetchUserPlansQuery: any;
  isEditing: boolean;
  currentPlanId: string | null;
  updatePlan: (planId: string, updatedPlan: Partial<Plan>) => Promise<void>;
  bookResyReservation: (venueId: string, configId: string, partySize: number, date: string, time: string) => Promise<void>;
  findResyVenue: (venueName: string, lat: number, lng: number, partySize: number, date?: string) => Promise<any>;
}

const PlanContext = createContext<PlanContextType>({
  activities: [],
  setActivities: () => { },
  savePlan: async () => ({ name: '', activities: [] }),
  isAtMaxCapacity: false,
  loadPlanForEdit: () => { },
  deletePlan: async () => { },
  fetchUserPlansQuery: async () => [],
  isEditing: false,
  currentPlanId: null,
  updatePlan: async () => { },
  bookResyReservation: async () => { },
  findResyVenue: async () => { },
});

export const PlanProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const auth = useAuth()
  const queryClient = useQueryClient()
  const [activities, setActivities] = useState<PlanActivity[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentPlanId, setCurrentPlanId] = useState<string | null>(null);
  const navigate = useNavigate();

  const isAtMaxCapacity = activities.length >= 3;

  const savePlan = async (name: string): Promise<Plan> => {
    if (activities.length > 3) {
      throw new Error('Plan cannot have more than 3 activities');
    }

    const planData: Plan = {
      name,
      activities,
      ...(isEditing && currentPlanId ? { updated_at: new Date().toISOString() } : {})
    };

    try {
      let response;
      if (isEditing && currentPlanId) {
        response = await APIClient.patch(`/plans/${currentPlanId}/`, planData);
      } else {
        response = await APIClient.post(`/plans/`, planData);
      }

      setActivities([]);
      setIsEditing(false);
      setCurrentPlanId(null);
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 409) {
          throw new Error('Plan was modified by another user. Please refresh and try again.');
        }
        throw new Error(error.response?.data?.detail || 'Failed to save plan');
      }
      throw error;
    }
  };

  const loadPlanForEdit = (plan: Plan) => {
    if (!plan.activities || plan.activities.length > 3) {
      throw new Error('Invalid plan data');
    }
    setActivities(plan.activities);
    setIsEditing(true);
    setCurrentPlanId(plan.id || null);
    navigate('/map');
  };

  const deletePlan = async (planId: string): Promise<void> => {
    try {
      await APIClient.delete(`${APIEndpoint}/plans/${planId}/`);
      queryClient.invalidateQueries({ queryKey: ['userPlans'] })
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.detail || 'Failed to delete plan');
      }
      throw error;
    }
  };

  const fetchUserPlans = async (): Promise<Plan[]> => {
    try {
      const response = await APIClient.get(`${APIEndpoint}/plans/`);
      return response.data.results;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.log(error.response?.data?.detail || 'Failed to fetch plans');
      }
      throw error;
    }
  };

  const updatePlan = async (planId: string, updatedPlan: Partial<Plan>): Promise<void> => {
    try {
      await APIClient.patch(`${APIEndpoint}/plans/${planId}/`, updatedPlan);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.detail || 'Failed to update plan');
      }
      throw error;
    }
  };

  const fetchUserPlansQuery = useQuery({
    queryKey: ['userPlans'],
    queryFn: fetchUserPlans,
    staleTime: 1 * 60 * 1000,
    enabled: auth.isAuthenticated || false,
    retry: false
  });

  const bookResyReservation = async (
    venueId: string, 
    configId: string, 
    partySize: number, 
    date: string, 
    time: string
  ) => {
    try {
      const response = await APIClient.post(`${APIEndpoint}/resy/book`, {
        venue_id: venueId,
        config_id: configId,
        party_size: partySize,
        date,
        time
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.detail || 'Failed to book reservation');
      }
      throw error;
    }
  };

  const findResyVenue = async (
    venueName: string,
    lat: number,
    lng: number,
    partySize: number,
    date?: string
  ) => {
    try {
      const response = await APIClient.get(`${APIEndpoint}/resy/venues`, {
        params: { venue_name: venueName, lat, lng, party_size: partySize, date }
      });
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        throw new Error(error.response?.data?.detail || 'Failed to find venue');
      }
      throw error;
    }
  };

  return (
    <PlanContext.Provider value={{
      activities,
      setActivities,
      savePlan,
      isAtMaxCapacity,
      loadPlanForEdit,
      deletePlan,
      fetchUserPlansQuery,
      isEditing,
      currentPlanId,
      updatePlan,
      bookResyReservation,
      findResyVenue
    }}>
      {children}
    </PlanContext.Provider>
  );
};

export const usePlan = () => useContext(PlanContext);