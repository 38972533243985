import React from 'react';
import { Typography, Box } from '@mui/material';
import './Settings.scss';

const Settings: React.FC = () => {
  return (
    <Box className="settings">
      <Typography variant="h4">Settings</Typography>
      <Typography>This is a placeholder for the settings page.</Typography>
    </Box>
  );
}

export default Settings;
