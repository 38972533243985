import React, { useEffect, useState } from 'react';
import './Itinerary.scss';
import {
  Box,
  Typography,
  IconButton,
  Paper,
  Collapse,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SaveIcon from '@mui/icons-material/Save';
import { usePlan } from '../../hooks/plan_context';
import { motion, AnimatePresence } from 'framer-motion';
import { useQueryClient } from '@tanstack/react-query';

const Itinerary: React.FC = () => {
  const queryClient = useQueryClient();
  const { activities, setActivities, savePlan, isAtMaxCapacity } = usePlan();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false);
  const [planName, setPlanName] = useState('');
  const [saveError, setSaveError] = useState('');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const removeActivity = (id: string) => {
    setActivities(prev => prev.filter(activity => activity.id !== id));
  };

  const handleSave = async () => {
    if (!planName.trim()) {
      setSaveError('Please enter a plan name');
      return;
    }

    try {
      await savePlan(planName);
      await queryClient.invalidateQueries({ queryKey: ['userPlans'] });
      setSaveDialogOpen(false);
      setPlanName('');
      setSaveError('');
    } catch (error) {
      setSaveError('Failed to save plan. Please try again.');
    }
  };

  if (activities.length === 0) return null;

  return (
    <>
      <Paper className="itinerary-container" elevation={3}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6">
            Your Plan {isAtMaxCapacity && <span className="max-capacity-indicator">(Full)</span>}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            {!isMobile && (
              <IconButton size="small" onClick={() => setIsCollapsed(!isCollapsed)}>
                {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            )}
            {activities.length > 0 && (
              <IconButton
                size="small"
                onClick={() => setSaveDialogOpen(true)}
                color="primary"
              >
                <SaveIcon />
              </IconButton>
            )}
          </Box>
        </Box>

        {isAtMaxCapacity && (
          <Alert severity="info" sx={{ mb: 2 }}>
            Maximum of 3 places reached
          </Alert>
        )}

        <Collapse in={!isCollapsed}>
          <AnimatePresence>
            {activities.map((activity, index) => (
              <motion.div
                key={activity.id}
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -20 }}
                className="itinerary-item"
              >
                <Box>
                  <Typography variant="body1">
                    {index + 1}. {activity.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary" className="itinerary-address">
                    {activity.vicinity}
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={() => removeActivity(activity.id)}
                  className="remove-button"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </motion.div>
            ))}
          </AnimatePresence>
        </Collapse>
      </Paper>

      <Dialog open={isSaveDialogOpen} onClose={() => setSaveDialogOpen(false)}>
        <DialogTitle>Save Your Plan</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Plan Name"
            fullWidth
            variant="outlined"
            value={planName}
            onChange={(e) => setPlanName(e.target.value)}
            error={!!saveError}
            helperText={saveError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSaveDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">Save</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Itinerary;