import React, { useState, useEffect, useContext } from 'react';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Dashboard, Map, Lightbulb } from '@mui/icons-material';
import { useNavigate, useLocation } from 'react-router-dom';
import './BottomNav.scss';
import { AuthContext } from '../../hooks/auth_provider';

const BottomNav: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState(0);
  const authContext = useContext(AuthContext);
  const [visible, setVisible] = useState(authContext.isAuthenticated)

  useEffect(() => {
    const pathToIndex: { [key: string]: number } = {
      '/': 0,
      '/map': 1,
      '/inspiration': 2,
    };
    setValue(pathToIndex[location.pathname] || 0);
  }, [location]);

  useEffect(() => {
    setVisible(authContext.isAuthenticated);
  }, [authContext])

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    const indexToPath = ['/', '/map', '/inspiration'];
    navigate(indexToPath[newValue]);
  };

  return (
    visible ?
      <Paper className="bottom-nav" elevation={3}>
        <BottomNavigation value={value} onChange={handleChange} showLabels>
          <BottomNavigationAction label="Dashboard" icon={<Dashboard />} />
          <BottomNavigationAction label="Map" icon={<Map />} />
          <BottomNavigationAction label="Inspiration" icon={<Lightbulb />} />
        </BottomNavigation>
      </Paper> : <></>
  );
}

export default BottomNav;
