import * as React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { NavLink, useNavigate } from "react-router-dom";
import { Grid } from '@mui/material';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { useContext, useState } from 'react';
import { AuthContext } from '../../hooks/auth_provider';
import { CardBase } from '../Card/CardBase';


export default function Login() {
  const [expanded, setExpanded] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [showErrorText, setShowErrorText] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const auth = useContext(AuthContext);


  const isValidEmail = (email: string) => {
    const emailRegex = new RegExp("^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$");
    return emailRegex.test(email);
  }

  const isValidPassword = (password: string) => {
    const passwordRegex = new RegExp("^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,}$");
    return passwordRegex.test(password);
  }

  const responseMessage = async (response: CredentialResponse) => {
    try {
      await auth.handleSocialAuthTokenExchange(response)
      navigate("/")
      setShowErrorText(false);
    } catch (e) {
      setShowErrorText(true);
    }
  };

  const errorMessage = () => {
    console.log("An unknown error occurred.");
  };

  const handleLoginExpandClick = () => setExpanded(!expanded)

  const handleLoginSubmitClick = () => {
    setSubmitDisabled(true);
    console.debug("TODO: HTTP request to authentication endpoint")
    // this.setState({ expanded: !this.state.expanded });
  };

  const handleLoginFormChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, formPart: string) => {
    let isValid = false;

    switch (formPart) {
      case 'email': {
        console.debug(event.target.value);
        setEmail(event.target.value)
        isValid = isValidEmail(event.target.value) && isValidPassword(password)
        break;
      }
      case 'password': {
        console.debug(event.target.value);
        setPassword(event.target.value)
        isValid = isValidPassword(event.target.value) && isValidEmail(email)
        break;
      }
    }

    if (isValid) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
  }

  if (expanded) {
    return <CardBase
      className='login'
      content={<Typography variant="body2" color="text.secondary">Enter your credentials below.</Typography>}
      actions={
        <Grid container padding={'12px;'} spacing={3}>
          <Grid item xs={12}><TextField required label="Email" value={email} onChange={(e) => handleLoginFormChange(e, 'email')} data-testid="login-email-input" /></Grid>
          <Grid item xs={12}><TextField required label="Password" type="password" value={password} onChange={(e) => handleLoginFormChange(e, 'password')} data-testid="login-password-input" /></Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs><Button onClick={() => handleLoginExpandClick()}>Back</Button></Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs><Button variant="contained" disabled={submitDisabled} onClick={() => handleLoginSubmitClick()}>Submit</Button></Grid>
        </Grid>
      }></CardBase>
  } else {
    return <CardBase
      className='login'
      content={<>
        <Typography variant="body2" color="text.secondary">Login or&nbsp;<NavLink to="/signup">sign up</NavLink>&nbsp;to continue</Typography>
        <Typography variant="body2" color="text.secondary" onClick={() => handleLoginExpandClick()}>Click here to login with email/password</Typography>
      </>
      }
      actions={
        <Grid container spacing={0} padding={'12px;'} direction="row" alignItems="center" justifyContent="center">
          <Grid item xs={'auto'}>
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} auto_select={false} useOneTap={false} />
          </Grid>
          <Grid item xs={12}>&nbsp;</Grid>
          {showErrorText && <Grid item><Typography variant="body2" color="red">Something went wrong; try again.</Typography></Grid>}
        </Grid>
      }></CardBase>;
  }
}