import { Alert, Box, CircularProgress, Typography } from '@mui/material';
import { APIEndpoint, APIClient } from '../../lib/api_client';
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import { usePlan } from '../../hooks/plan_context';
import { useQuery } from '@tanstack/react-query';
import ActivityDrawer from '../ActivityDrawer/ActivityDrawer';
import Cookies from 'js-cookie';
import Itinerary from '../Itinerary/Itinerary';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import './Map.scss';

export interface CustomMapMarker {
  lat: number;
  lng: number;
  name: string;
  place_id?: string;
  price_level?: number;
  rating?: number;
  types?: string[];
  user_ratings_total?: number;
  vicinity?: string;
  photos?: {
    photo_reference: string;
    height: number;
    width: number;
  }[];
  opening_hours?: {
    open_now: boolean;
    weekday_text: string[];
  };
  reviews?: {
    author_name: string;
    rating: number;
    text: string;
  }[];
  // Add any additional properties you intend to use
}

const stubCustomMapMarkers: CustomMapMarker[] = [
  { lat: 40.7128, lng: -74.0060, name: 'New York City', place_id: '1' },
  { lat: 34.0522, lng: -118.2437, name: 'Los Angeles', place_id: '2' },
  { lat: 41.8781, lng: -87.6298, name: 'Chicago', place_id: '3' },
];

const getIconUrl = (marker: CustomMapMarker): string => {
  // We could use types, but they need to fit within those types allowable in this big list:
  // https://developers.google.com/maps/documentation/places/web-service/supported_types
  // Note that types are not mutually exclusive. A place may have both food and bar types.
  //
  // We'll do price range for now: Free, Inexpensive, Moderate, Expensive, Very Expensive
  let logo_path = `/logo-red.png`;
  let colors = ['green', 'yellow', 'magenta', 'purple', 'red']
  if (marker.price_level) logo_path = `/logo-${colors[marker.price_level]}.png`;

  return logo_path
}

const MemoizedMarker = memo(({ marker, onClick }: { marker: CustomMapMarker; onClick: () => void }) => (
  <Marker
    key={marker.place_id}
    position={{ lat: marker.lat, lng: marker.lng }}
    onClick={onClick}
    title={marker.name}
    icon={{ url: getIconUrl(marker), scaledSize: new google.maps.Size(24, 24) }}
  />
));

const fetchMarkers = async (userLocation: google.maps.LatLngLiteral | null): Promise<CustomMapMarker[]> => {
  const response = await APIClient.get(`${APIEndpoint}/markers/`, {
    headers: { Accept: 'application/json' },
    params: { lat: userLocation?.lat || 40.719632, lng: userLocation?.lng || -73.984810 }
  });
  return response.data;
};

const Map: React.FC = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""
  });
  const [userLocation, setUserLocation] = useState<google.maps.LatLngLiteral | null>(null);
  const [mapMarkers, setCustomMapMarkers] = useState<CustomMapMarker[]>(stubCustomMapMarkers);
  const [markerInfo, setMarkerInfo] = useState<CustomMapMarker | null>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const mapRef = useRef<google.maps.Map | null>(null);
  const center = userLocation || stubCustomMapMarkers[0];
  const { activities } = usePlan();
  const [isEditMode] = useState(activities.length > 0);
  const [isLoadingMarkers, setIsLoadingMarkers] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchMarkersQuery = useQuery({
    queryKey: ['fetchMarkers'],
    queryFn: () => fetchMarkers(userLocation),
    staleTime: 2 * 60 * 60 * 1000, // 2 hours
    enabled: !!Cookies.get("mindara_at"),
    retry: false
  });

  const getUserLocation = useCallback(() => {
    if (!navigator.geolocation) {
      console.error('Geolocation is not supported by this browser.');
      return;
    }

    let watchId: number;

    const success = (position: GeolocationPosition) => setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude });
    const error = (error: GeolocationPositionError) => console.error('Error getting user location:', error);

    // The HTML5 Geolocation APIs don't work in non-HTTPS contexts (e.g. local development)
    if (process.env.NODE_ENV === "production") {
      watchId = navigator.geolocation.watchPosition(success, error, {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
      });
    } else {
      console.info("Setting user location manually due to insecure network context.")
      setUserLocation({ lat: 40.71959793902819, lng: -73.98479546512463 });
    }

    return () => {
      if (watchId) navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  useEffect(() => {
    const cleanup = getUserLocation();
    return () => cleanup?.();
  }, [getUserLocation]);

  const getCustomMapMarkers = useCallback(async () => {
    if (!userLocation) return;

    setIsLoadingMarkers(true);
    setError(null);

    try {
      if (!fetchMarkersQuery.isLoading) {
        const markers = fetchMarkersQuery.data
        setCustomMapMarkers(markers || []);
      }
    } catch (error) {
      console.error('Error fetching map markers:', error);
      setError('Unable to load locations. Please try again later.');
      setCustomMapMarkers(stubCustomMapMarkers);
    } finally {
      setIsLoadingMarkers(false);
    }
  }, [userLocation, fetchMarkersQuery]);

  const toggleDrawer = (open: boolean) => () => {
    console.log("Toggling drawer")
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (isEditMode && activities.length > 0) {
      // Center map on first activity when in edit mode
      const firstActivity = activities[0];
      setUserLocation({
        lat: firstActivity.lat,
        lng: firstActivity.lng,
      });
    } else {
      getUserLocation();
    }
  }, [isEditMode, activities, getUserLocation]);

  useEffect(() => {
    if (userLocation) {
      getCustomMapMarkers();
    }
  }, [userLocation, getCustomMapMarkers]);

  const markerClickHandler = ((marker: CustomMapMarker) => {
    console.debug("Marker clicked: ", marker)
    setMarkerInfo(marker);
    setDrawerOpen(true); // Open the drawer when the marker is clicked
  })

  // const fetchRecommendations = useCallback(async () => {
  //   try {
  //     if (userLocation) {
  //       const response = await axios.get(`${APIEndpoint}/discovery/maps/recommendations`, {
  //         headers: { Accept: 'application/json' },
  //         params: { lat: userLocation?.lat, lng: userLocation?.lng },
  //       });
  //       console.log(response);
  //       // setRecommendations(response.data.recommendations);
  //     }
  //   } catch (error) {
  //     console.error('Error fetching recommendations:', error);
  //   }
  // }, [userLocation]);

  // useEffect(() => {
  //   const handler = setTimeout(() => {
  //     fetchRecommendations();
  //   }, 500);

  //   return () => {
  //     clearTimeout(handler);
  //   };
  // }, [fetchRecommendations]);

  if (loadError) return <div>Error loading maps</div>;
  if (!isLoaded) return <CircularProgress />;

  return (
    <>
      {error && (
        <Alert
          severity="error"
          onClose={() => setError(null)}
          sx={{ position: 'absolute', top: 16, left: 16, zIndex: 1 }}
        >
          {error}
        </Alert>
      )}

      <GoogleMap
        mapContainerClassName={`map-component ${isLoadingMarkers ? 'loading' : ''}`}
        center={center}
        zoom={15}
        onLoad={(map: google.maps.Map) => {
          mapRef.current = map;
        }}
        options={{
          gestureHandling: 'greedy',
          disableDefaultUI: false,
          zoomControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          mapTypeId: "roadmap",
          mapId: "c028d42ad5f33521" // See https://console.cloud.google.com/google/maps-apis/studio/styles?project=mindara-production
        }}
      >
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{ path: window.google.maps.SymbolPath.CIRCLE, scale: 8, fillColor: '#4285F4', fillOpacity: 1, strokeWeight: 2, strokeColor: '#ffffff' }}
            title="Your Location"
            aria-label="User Location Marker"
          />
        )}
        {mapMarkers.map((marker) => (
          <MemoizedMarker
            key={marker.place_id}
            marker={marker}
            onClick={() => markerClickHandler(marker)}
          />
        ))}
        <ActivityDrawer
          open={drawerOpen}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
          markerInfo={markerInfo}
        />
      </GoogleMap>

      {isLoadingMarkers && (
        <Box className="map-loading-overlay">
          <CircularProgress size={24} />
          <Typography variant="body2" sx={{ ml: 1 }}>
            Loading locations...
          </Typography>
        </Box>
      )}

      <Itinerary />
    </>
  )
}

export default Map;
