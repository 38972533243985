import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { ThemeProvider } from '@emotion/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from './hooks/auth_provider';
import { InspirationProvider } from './hooks/inspiration_context';
import { PlanProvider } from './hooks/plan_context';
import { createTheme } from '@mui/material';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import BrowserRouterWithEffects from './lib/browser_router_with_effects';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, staleTime: 1 * (60 * 1000) } } // Milliseconds
})

const theme = createTheme({
  palette: {
    primary: { main: '#4285F4' },
    secondary: { main: '#DB4437' },
    background: { default: '#f1f3f4' },
    text: { primary: '#202124', secondary: '#5f6368' }
  },
  typography: { fontFamily: 'Roboto, sans-serif' },
  components: {
    MuiDrawer: { styleOverrides: { paper: { backgroundColor: '#ffffff' } } },
    MuiButton: { styleOverrides: { root: { borderRadius: '24px', textTransform: 'none' } } }
  },
});

root.render(
  <React.StrictMode>
    <link rel="preconnect" href="https://fonts.googleapis.com" crossOrigin="anonymous" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
    <link
      rel="stylesheet"
      href="https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap"
      crossOrigin="anonymous"
    />
    <div className="index-base">
      <header className="index-header">
        <ErrorBoundary>
          <BrowserRouterWithEffects>
            <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ""}>
              <QueryClientProvider client={queryClient}>
                <AuthProvider>
                  <PlanProvider>
                    <InspirationProvider>
                      <ThemeProvider theme={theme}>
                        <App />
                      </ThemeProvider>
                    </InspirationProvider>
                  </PlanProvider>
                </AuthProvider>
                {process.env.NODE_ENV !== 'production' && (<ReactQueryDevtools initialIsOpen={false} />)}
              </QueryClientProvider>
            </GoogleOAuthProvider>
          </BrowserRouterWithEffects>
        </ErrorBoundary>
      </header>
    </div>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();