import React, { useCallback, useEffect, useState } from 'react';
import './Dashboard.scss';
import {
  Box,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Alert,
  TextField,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import { usePlan, Plan } from '../../hooks/plan_context';
import { motion, AnimatePresence } from 'framer-motion';

const Dashboard: React.FC = () => {
  const { fetchUserPlansQuery, loadPlanForEdit, deletePlan, bookResyReservation, findResyVenue } = usePlan();
  const [plans, setPlans] = useState<Plan[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState<Plan | null>(null);
  const [resyDialogOpen, setResyDialogOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Plan | null>(null);
  const [resyBookingDetails, setResyBookingDetails] = useState({
    date: '',
    time: '',
    partySize: 2
  });

  const loadPlans = useCallback(async () => {
    try {
      setLoading(true);
      if (!fetchUserPlansQuery.isLoading) {
        const userPlans = fetchUserPlansQuery.data
        setPlans(userPlans);
      }
      setError(null);
    } catch (err) {
      setError('Failed to load plans. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [fetchUserPlansQuery]);

  const handleEdit = (plan: Plan) => {
    loadPlanForEdit(plan);
  };

  const handleDeleteClick = (plan: Plan) => {
    setPlanToDelete(plan);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!planToDelete?.id) return;

    try {
      await deletePlan(planToDelete.id);
      setPlans(plans.filter(p => p.id !== planToDelete.id));
      setDeleteDialogOpen(false);
      setPlanToDelete(null);
    } catch (err) {
      setError('Failed to delete plan. Please try again.');
    }
  }

  useEffect(() => { loadPlans() }, [loadPlans])

  const handleResyBook = async (plan: Plan) => {
    setSelectedPlan(plan);
    setResyDialogOpen(true);
  };

  const handleResyBookingSubmit = async () => {
    if (!selectedPlan) return;
    
    try {
      const restaurant = selectedPlan.activities[0]; // Assuming first activity is restaurant
      const venues = await findResyVenue(
        restaurant.name,
        restaurant.lat,
        restaurant.lng,
        resyBookingDetails.partySize,
        resyBookingDetails.date
      );

      if (venues && venues.length > 0) {
        const venue = venues[0];
        await bookResyReservation(
          venue.id,
          venue.config_id,
          resyBookingDetails.partySize,
          resyBookingDetails.date,
          resyBookingDetails.time
        );
      }
      setResyDialogOpen(false);
    } catch (error) {
      console.error('Failed to book reservation:', error);
    }
  };

  if (loading) {
    return (
      <Box className="dashboard-loading">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box className="dashboard-container">
      <Typography variant="h4" gutterBottom>
        Your Plans
      </Typography>

      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}

      <Grid container spacing={3}>
        <AnimatePresence>
          {plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.id}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
              >
                <Card className="plan-card">
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {plan.name}
                    </Typography>
                    <Typography color="textSecondary" gutterBottom>
                      {new Date(plan.created_at!).toLocaleDateString()}
                    </Typography>
                    <Box className="plan-activities">
                      {plan.activities.map((activity, index) => (
                        <Typography key={activity.id} variant="body2">
                          {index + 1}. {activity.name}
                        </Typography>
                      ))}
                    </Box>
                  </CardContent>
                  <CardActions className="plan-actions">
                    <Button
                      startIcon={<EditIcon />}
                      onClick={() => handleEdit(plan)}
                      color="primary"
                    >
                      Edit
                    </Button>
                    <Button
                      startIcon={<DeleteIcon />}
                      onClick={() => handleDeleteClick(plan)}
                      color="error"
                    >
                      Delete
                    </Button>
                    <Button
                      startIcon={<RestaurantIcon />}
                      onClick={() => handleResyBook(plan)}
                      color="primary"
                    >
                      Book Table
                    </Button>
                  </CardActions>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </AnimatePresence>
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
      >
        <DialogTitle>Delete Plan</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete "{planToDelete?.name}"? This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={resyDialogOpen} onClose={() => setResyDialogOpen(false)}>
        <DialogTitle>Book Restaurant Reservation</DialogTitle>
        <DialogContent>
          <TextField
            label="Date"
            type="date"
            value={resyBookingDetails.date}
            onChange={(e) => setResyBookingDetails(prev => ({ ...prev, date: e.target.value }))}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Time"
            type="time"
            value={resyBookingDetails.time}
            onChange={(e) => setResyBookingDetails(prev => ({ ...prev, time: e.target.value }))}
            fullWidth
            margin="normal"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Party Size"
            type="number"
            value={resyBookingDetails.partySize}
            onChange={(e) => setResyBookingDetails(prev => ({ ...prev, partySize: parseInt(e.target.value) }))}
            fullWidth
            margin="normal"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setResyDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleResyBookingSubmit} variant="contained" color="primary">
            Book Now
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
