import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import { CardActions } from '@mui/material';

interface CardBaseComponentProps {
    actions: React.ReactNode;
    content: React.ReactNode;
    className?: string;
}

export class CardBase extends React.Component<CardBaseComponentProps, {}> {
    render() {
        return (
            <Card className={this.props.className || ''} sx={{ maxWidth: 345 }}>
                <CardHeader title="Mindara" />
                <CardMedia
                    component="img"
                    image={require('../../assets/login_banner.png')}
                    alt="Mindara Banner"
                />
                <CardContent>
                    {this.props.content}
                </CardContent>
                <CardActions disableSpacing sx={{ flexGrow: 1 }}>
                    {this.props.actions}
                </CardActions>
            </Card >
        )
    }
}
