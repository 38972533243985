import React, { createContext, useState } from 'react';

// Export the InspirationItem interface
export interface InspirationItem {
    id: string;
    type: 'restaurant' | 'event' | 'place';
    name: string;
    description: string;
    imageUrl: string;
}

// Define the context value type
interface InspirationContextType {
    inspirationItems: InspirationItem[];
    setInspirationItems: React.Dispatch<React.SetStateAction<InspirationItem[]>>;
}

// Create the InspirationContext
export const InspirationContext = createContext<InspirationContextType>({
    inspirationItems: [],
    setInspirationItems: () => { },
});

// Create a provider component
export const InspirationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [inspirationItems, setInspirationItems] = useState<InspirationItem[]>([]);

    return (
        <InspirationContext.Provider value={{ inspirationItems, setInspirationItems }}>
            {children}
        </InspirationContext.Provider>
    );
};